.language-selector {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1001;
}

.language-flag {
    margin: 7px;
    width: 25px;
}

.selected-language {
  border-radius: 50%;
  box-shadow: 0px 0px 9px 7px #FFFFFF;
}