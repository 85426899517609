


.blue-sign-popup {
    color: #ffffff;
    font-family: Roboto;
    width: 260px;
    font: Roboto;
}

.blue-sign-popup .leaflet-popup-content-wrapper, .blue-sign-popup .leaflet-popup-tip {
    background: #0087ff;
    color: #ffffff;
}


.blue-sign-popup .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
}

.blue-sign-popup .subHeader {
    font-size: 14px;
    font-weight: bold;
}

.blue-sign-popup .text {
    margin-top: 7px;
    font-weight: normal;
    font-size: 16px;
    height: 190px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: 100%;
}

.blue-sign-popup .attribution {
    float: right;
    overflow: auto;
    margin-right: 10px;
    font-size: 14px;
}

.blue-sign-popup .appendum {
    position: relative;
}

.blue-sign-popup .appendum:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background:linear-gradient(transparent 150px, #0087ff);
}

.leaflet-popup-content {
    margin: 15px 5px 15px 15px;
}

.blue-sign-popup .thumbnail {
    margin-right: 10px;
    margin-bottom: 7px;
}

.blue-sign-popup .thumbnail img {
    width: 100%;
}

/*
https://www.digitalocean.com/community/tutorials/css-scrollbars

   The emerging W3C standard
   that is currently Firefox-only */
.blue-sign-popup * {
  scrollbar-width: thin;
  scrollbar-color: #96bfff #0087ff;
}

/* Works on Chrome/Edge/Safari */
.blue-sign-popup *::-webkit-scrollbar {
  width: 10px;
}
.blue-sign-popup *::-webkit-scrollbar-track {
  background: #0087ff;
}
.blue-sign-popup *::-webkit-scrollbar-thumb {
  background-color: #96bfff;
  border-radius: 20px;
  border: 3px solid #0087ff;
}

.language-selector {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1001;
}

.language-flag {
    margin: 7px;
    width: 25px;
}

.selected-language {
  border-radius: 50%;
  box-shadow: 0px 0px 9px 7px #FFFFFF;
}
.leaflet-container {
    height: 100vh;
    width: 100%;
    padding-left: 0px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

